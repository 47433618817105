export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "navbarHeadings": {
          "products": {
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
            "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantum Education"])},
            "enabling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumentation"])},
            "setups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confocal Microscope Systems"])}
          },
          "services": {
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
            "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom solutions"])},
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses & Training"])}
          },
          "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
          "about": {
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About AQ"])},
            "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Positions"])},
            "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["References"])}
          }
        }
      },
      "de": {
        "navbarHeadings": {
          "products": {
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
            "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantenlehre"])},
            "enabling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messtechnik"])},
            "setups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfokale Mikroskopsysteme"])}
          },
          "services": {
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
            "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenspezifische Lösungen"])},
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurse & Fortbildungen"])}
          },
          "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösungen"])},
          "about": {
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über AQ"])},
            "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karriere"])},
            "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzen"])}
          }
        }
      }
    }
  })
}
