<template>
  <div class="main-wrapper">
    <header>
      <component :is="isLanding ? 'HeroSection' : 'TopNavbar'">
        <div class="navbar-item has-dropdown is-hoverable">
          <router-link class="navbar-link" active-class="is-active" to="/products" >
            {{ t('navbarHeadings.products.main') }}
            <span @click.prevent="handleProductsClicked"></span>
          </router-link>                

          <div class="navbar-dropdown">            
            <router-link class="navbar-item"  active-class="is-active" to="/products/education/quantum-sensing">
              {{ t('navbarHeadings.products.education') }}
            </router-link>
            <!--
            <router-link class="navbar-item"  active-class="is-active" to="/products/enabling">
              {{ t('navbarHeadings.products.enabling') }}
            </router-link>
            <router-link class="navbar-item"  active-class="is-active" to="/products/setups">
              {{ t('navbarHeadings.products.setups') }}
            </router-link>
            -->
          </div>      
        </div>  

        <router-link class="navbar-item" active-class="is-active" to="/services">
          {{ t('navbarHeadings.services.main') }}
        </router-link>
        <!--                    
        <div class="navbar-item has-dropdown is-hoverable">     
          <router-link class="navbar-link" active-class="is-active" to="/services">
            {{ t('navbarHeadings.services.main') }}
          </router-link>
          <div class="navbar-dropdown">  
            <router-link class="navbar-item"  active-class="is-active" to="/solutions">
              {{ t('navbarHeadings.services.custom') }}
            </router-link>                   
            <router-link class="navbar-item"  active-class="is-active" to="/courses">
              {{ t('navbarHeadings.services.courses') }}
            </router-link>     
          </div>   
        </div>               
          -->
        <router-link class="navbar-item" active-class="is-active" to="/solutions">
          {{ t('navbarHeadings.solutions') }}
        </router-link>        
        
        <div class="navbar-item has-dropdown is-hoverable">
          <router-link class="navbar-link" active-class="is-active" to="/about" >
            {{ t('navbarHeadings.about.main') }}
            <span @click.prevent="handleProductsClicked"></span>
          </router-link>                

          <div class="navbar-dropdown">            
            <!--<router-link class="navbar-item"  active-class="is-active" to="/references">
              {{ t('navbarHeadings.about.references') }}
            </router-link>            -->
            <router-link class="navbar-item"  active-class="is-active" to="/jobs">
              {{ t('navbarHeadings.about.jobs') }}
            </router-link>
            <!--
            <router-link class="navbar-item"  active-class="is-active" to="/products/enabling">
              {{ t('navbarHeadings.products.enabling') }}
            </router-link>
            <router-link class="navbar-item"  active-class="is-active" to="/products/setups">
              {{ t('navbarHeadings.products.setups') }}
            </router-link>
            -->
          </div>        
        </div>                    

        <!--
       <div class="navbar-item has-dropdown is-hoverable">
          <router-link class="navbar-link" active-class="is-active" to="/about">
            {{ t('navbarHeadings.about.main') }}
          </router-link>
          <div class="navbar-dropdown">            
            <router-link class="navbar-item"  active-class="is-active" to="/jobs">
              {{ t('navbarHeadings.about.jobs') }}
            </router-link>     
          </div>           
        </div>
        -->
      </component>
    </header>
    <div class="main-content">
        <router-view></router-view>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import HeroSection from "@/components/HeroSection.vue";
import TopNavbar from "@/components/TopNavbar.vue";
import PageFooter from "@/components/PageFooter.vue";

export default {
  name: 'App',
  data() {
    return { 
      langauge: "de",
      productDropdownOpened: false,
      aboutDropdownOpened: false,
      isMobile: false
      };
  },
  setup() {
    const { t } = useI18n();
    return { t } 
  },
  mounted () {
    let mediaquery = window.matchMedia('(max-width: 1024px)')
    this.isMobile = mediaquery.matches
    mediaquery.addEventListener('change', this.handleMediaQueryChanged)
  },
  computed: {
    isLanding() {
      return this.$route.name === "landing";
    }
  },
  methods: {
    handleMediaQueryChanged(mediaQueryList) {
      this.isMobile = mediaQueryList.matches
    },
    handleProductsClicked() {
      if(this.isMobile ) {
        this.productDropdownOpened !=  this.productDropdownOpened
        // console.log("Its mobile...")
        // return event.stopPropagation()
      } else {
        // console.log("Its not mobile...")
        this.$router.push("/products")
      }
    },
    handleAboutClicked() {
      if(this.isMobile ) {
        this.aboutDropdownOpened !=  this.aboutDropdownOpened
        // console.log("Its mobile...")
        // return event.stopPropagation()
      } else {
        // console.log("Its not mobile...")
        this.$router.push("/about")
      }
    }    
  },
  components: {
    HeroSection,
    TopNavbar,
    PageFooter,
  }
};
</script>

<style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .main-wrapper {
    display:flex;
    min-height: 100vh;
    flex-direction: column;    
  }

  .main-content {
    flex: 1 1 0%;
  }
</style>

<i18n>
{
  "en": {
    "navbarHeadings":{
      "products": { 
        "main":"Products",   
        "education" : "Quantum Education",
        "enabling" : "Instrumentation",
        "setups" : "Confocal Microscope Systems"
      },
      "services":{ 
        "main":"Services",
        "custom" : "Custom solutions",
        "courses" : "Courses & Training"
      },
      "solutions":"Solutions",
      "about":{ 
        "main":"About AQ",
        "jobs" : "Open Positions",
        "references" : "References"
      }      
    }
  },
  "de":{
    "navbarHeadings":{
      "products": { 
        "main":"Produkte",
        "education" : "Quantenlehre",
        "enabling" : "Messtechnik",
        "setups" : "Konfokale Mikroskopsysteme"
      },
      "services": { 
        "main":"Dienstleistungen",
        "custom" : "Kundenspezifische Lösungen",
        "courses" : "Kurse & Fortbildungen"
      },
      "solutions":"Lösungen",
      "about": { 
        "main":"Über AQ",
        "jobs" : "Karriere",
        "references" : "Referenzen"
      }      
    }
  }
}
</i18n>
