// import LandingPageView from '../views/LandingPageView.vue'

module.exports = { routes : [
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: "products" */ '../views/LandingPageView.vue'), // LandingPageView,
    meta: {
      title: 'Advanced Quantum - Your Quantum Advantage Provider.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum technology for high precision precision magnetometry, speciality sensing and electronic measurement solutions.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum technology for high precision precision magnetometry, speciality sensing and electronic measurement solutions.'
        }
      ]
    },    
  },
  {
    path: '/products',
    alias: '/produkte',
    name: 'products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue'),
    meta: {
      title: 'Advanced Quantum - Our products for your advantage.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum products for high precision magnetometry, speciality sensing, optics and instrumentation.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for high precision precision magnetometry, speciality sensing, optics and instrumentation.'
        }
      ]
    },       
  },
  /*
  {
    path: '/products/education',
    alias: '/produkte/lehre',
    name: 'products-education',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "products"  '../views/Products/Education/EducationGroupView.vue'),
    meta: {
      title: 'Advanced Quantum - Quantum Technology Education.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum products for education in quantum sensing, quantum computing and quantum technology.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for education in quantum sensing, quantum computing and quantum technology.'
        }
      ]
    },       
  },  
  */
  {
    path: '/products/education/quantum-sensing',
    alias: '/produkte/lehre/quantensensorik',
    name: 'products-education-qsensing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/Products/Education/QuantumSensingView.vue'),
    meta: {
      title: 'Advanced Quantum - Quantum Technology Education.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum products for education in quantum sensing.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for education in quantum sensing.'
        }
      ]
    },       
  }, 
  /*   
  {
    path: '/products/education/quantum-computing',
    alias: '/produkte/lehre/quantencomputing',
    name: 'products-education-qcomputing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "products"  '../views/Products/Education/QuantumComputingView.vue'),
    meta: {
      title: 'Advanced Quantum - Quantum Technology Education.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum products for education in quantum computing.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for education in quantum computing.'
        }
      ]
    },       
  },
  */
  /*
  {
    path: '/products/enabling',
    alias: '/produkte/labor',
    name: 'products-lab',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "products"  '../views/Products/Enabling/EnablingGroupView.vue'),
    meta: {
      title: 'Advanced Quantum - Instrumentation for Quantum Technology.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum products for research & development.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for research & development.'
        }
      ]
    },       
  }, 
  */
  /*
  {
    path: '/products/setups',
    alias: '/produkte/systeme',
    name: 'products-setups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Products/Setups/SetupsGroupView.vue'),
    meta: {
      title: 'Advanced Quantum - Our setups for your success.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum services in high precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum services in high precision precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        }
      ]
    }, 
  },
  {
    path: '/products/setups/low-temperature',
    alias: '/produkte/systeme/tieftemperatur',
    name: 'products-setups-lowtemp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "services"  '../views/Products/Setups/LowTemperatureView.vue'),
    meta: {
      title: 'Advanced Quantum - Our setups for your success.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum services in high precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum services in high precision precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        }
      ]
    }, 
  },     
  {
    path: '/products/setups/room-temperature',
    alias: '/produkte/systeme/raumtemperatur',
    name: 'products-setups-roomtemp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "services"  '../views/Products/Setups/RoomTemperatureView.vue'),
    meta: {
      title: 'Advanced Quantum - Our setups for your success.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum services in high precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum services in high precision precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        }
      ]
    }, 
  },     
  */
  {
    path: '/services',
    alias: '/dienstleistungen',
    name: 'services',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue'),
    meta: {
      title: 'Advanced Quantum - Our services for your advantage.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum services in high precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum services in high precision precision magnetometry, speciality sensing, optics and instrumentation solutions.'
        }
      ]
    }, 
  }, 
  /*
  {
    path: '/courses',
    alias: '/kurse',
    name: 'courses-overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(webpackChunkName: "products"  '../views/Courses/CoursesView.vue'),
    meta: {
      title: 'Advanced Quantum - Instrumentation for Quantum Technology.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum Kurse.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for research & development.'
        }
      ]
    },       
  },    
  {
    path: '/courses/quantum-sensing',
    alias: '/kurse/quanten-sensorik',
    name: 'courses-qsensing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "products"  '../views/Courses/QSensingView.vue'),
    meta: {
      title: 'Advanced Quantum - Instrumentation for Quantum Technology.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum Kurse.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for research & development.'
        }
      ]
    },       
  },   
  {
    path: '/courses/quantum-computing',
    alias: '/kurse/quanten-computing',
    name: 'courses-qcomputing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "products"  '../views/Courses/QComputingView.vue'),
    meta: {
      title: 'Advanced Quantum - Instrumentation for Quantum Technology.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum Kurse.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for research & development.'
        }
      ]
    },       
  },  
  {
    path: '/courses/access',
    alias: '/kurse/zugang',
    name: 'courses-access',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( webpackChunkName: "products"  '../views/Courses/CourseAccessView.vue'),
    meta: {
      title: 'Advanced Quantum - Instrumentation for Quantum Technology.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum Kurse.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum products for research & development.'
        }
      ]
    },       
  },      
  */     
  {
    path: '/solutions',
    alias: '/loesungen',
    name: 'solutions',
    component: () => import(/* webpackChunkName: "solutions" */ '../views/SolutionsView.vue'),
    meta: {
      title: 'Advanced Quantum - Our solutions for your advantage.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum solutions for high precision magnetometry, speciality sensing, optics and instrumentation.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum solutions for high precision precision magnetometry, speciality sensing, optics and instrumentation.'
        }
      ]
    }, 
  },   
  {
    path: '/about',
    alias: '/ueber-aq',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'Advanced Quantum - About us',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum offers services and solution in quantum technology.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum offers services and solution in quantum technology.'
        }
      ]
    },     
  },   
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import(/* webpackChunkName: "jobs" */  '../views/About/Jobs/JobsOverview.vue'),
    meta: {
      title: 'Advanced Quantum - Join us',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum is the place to be to bringt quantum technologies to use.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum is the place to be to bringt quantum technologies to use.'
        }
      ]
    },     
  },
  /*
  {
    path: '/references',
    alias: '/referenzen',
    name: 'references',
    component: () => import('../views/About/References/ReferencesOverview.vue'),
    meta: {
      title: 'Advanced Quantum - References',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum offers services and solution in quantum technology.'
        },
        {
          property: 'og:description',
          content: 'Advanced Quantum offers services and solution in quantum technology.'
        }
      ]
    },     
  },   
  */  
  {
    path: '/contact',
    alias: '/kontakt',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: 'Advanced Quantum - Contact us.',
      metaTags: [
        {
          name: 'description',
          content: 'Contact Advanced Quantum, your specialist in quantum technology.'
        },
        {
          property: 'og:description',
          content: 'Contact Advanced Quantum, your specialist in quantum technology.'
        }
      ]
    },     
  },
  
  {
    path: '/terms',
    alias: '/agb',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsView.vue'),
    meta: {
      title: 'Advanced Quantum - Terms & Conditions.',
      metaTags: [
        {
          name: 'description',
          content: 'Learn about Advanced Quantum general terms & conditions'
        },
        {
          property: 'og:description',
          content: 'Learn about Advanced Quantum general terms & conditions'
        }
      ]
    }, 
  },  
  {
    path: '/privacy',
    alias: '/datenschutz',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyView.vue'),
    meta: {
      title: 'Advanced Quantum - Privacy statement.',
      metaTags: [
        {
          name: 'description',
          content: 'Advanced Quantum values your privacy.'
        },
        {
          property: 'og:description',
          content: 'of Advanced Quantum values your privacy.'
        }
      ]
    },     
  }, 
  {
    path: '/imprint',
    alias: '/impressum',
    name: 'imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/ImprintView.vue'),
    meta: {
      title: 'Advanced Quantum - Imprint.',
      metaTags: [
        {
          name: 'description',
          content: 'Imprint of Advanced Quantum.'
        },
        {
          property: 'og:description',
          content: 'Imprint of Advanced Quantum.'
        }
      ]
    }, 
  },                            
  { path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFoundView.vue'),
    meta: {
      title: "Advanced Quantum - Sorry, we couldn't find what you where looking for."
    }, 
  },
]
}
