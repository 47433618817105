<template>
  <div
    class="navbar-underlay"
    v-if="!isLanding"
    :class="{ 'is-scrolled' : isScrolled }"
  ></div>
  <nav class="navbar is-fixed-top" :class="{ 'is-scrolled': isScrolled }">
    <div class="container">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img src="../assets/images/logo.svg" alt="Logo" />
        </router-link>
        <router-link class="navbar-item" to="/">Advanced Quantum</router-link>
        <a role="button" aria-label="menu" aria-expanded="false" class="navbar-burger" v-on:click="toggleMenu" v-bind:class="{ 'is-active' : mobileOpened }">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div class="navbar-menu" v-bind:class="{ 'is-active' : mobileOpened }" v-on:click="closeMenu">
        <div class="navbar-end">
          <slot></slot>
          <!--
          <span class="navbar-item">
              <router-link class="button is-outlined" to="/contact">
                <span class="icon">
                  <i class="fa-solid fa-envelope-open navbar-mail-hover"></i>
                  <i class="fa-solid fa-envelope navbar-mail"></i>
                </span>
              </router-link>
          </span>
        -->
          <router-link class="navbar-item"  to="/contact">
              <div class="button is-outlined">
                <span class="icon">
                  <i class="fa-solid fa-envelope-open navbar-mail-hover"></i>
                  <i class="fa-solid fa-envelope navbar-mail"></i>
                </span>
              </div>        
              </router-link>
          <locale-switcher></locale-switcher>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';

export default {
  name: "TopNavbar",
  components : {
    LocaleSwitcher,
  },
  data() {
    return {
      isScrolled: false,
      mobileOpened: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.isScrolled = window.scrollY !== 0;
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    isLanding() {
      return this.$route.name === "landing";
    },
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY !== 0;
    },
    toggleMenu() {
      this.mobileOpened = !this.mobileOpened;
    },
    closeMenu() {
      this.mobileOpened = false;
    }
  },
};
</script>


<style>

.navbar,
.hero .navbar {
  transition: min-height 0.5s;  
}

.navbar {
  -webkit-box-shadow: 0px 10px 13px -3px rgba(10, 10, 10, 0.51);
  box-shadow: 0px 10px 13px -3px rgba(10, 10, 10, 0.51);  
}

.hero .navbar:not(.is-scrolled) {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.25) 20%,
    rgba(255, 255, 255, 0.25) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.hero .navbar.is-scrolled {
  background-color: rgba(255, 255, 255, 1);
  background: white;
}

:not(.hero) *.navbar:not(.is-scrolled) {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.25) 20%,
    rgba(255, 255, 255, 0.25) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.navbar-underlay {
  background: linear-gradient(
        45deg,
        rgb(4 49 104 / 80%) 15%,
        rgba(48, 71, 9, 0.5) 60%,
        rgba(89, 81, 48, 0.5) 75%,
        rgb(135, 93, 0.5) 100%
      )
      0% 0%,
    url("../assets/images/hero.jpg") top center;
  background-size: cover;
  min-height: 3.25rem;
  transition: min-height 0.5s;
}

/* .navbar-link:not(.is-arrowless)::after, */
.navbar:not(.is-scrolled) * .navbar-item.has-dropdown .navbar-link:not(:hover):not(.is-arrowless) span,
.navbar:not(.is-scrolled) * :not(:hover) {
  color:white;
  border-color:white;  
}

.navbar-dropdown .navbar-item:not(:hover) a,
.navbar * .navbar-item.has-dropdown .navbar-link:not(:hover):not(.is-arrowless) span,
.navbar.is-scrolled * :not(:hover),
.navbar:not(.is-scrolled) * .navbar-dropdown :not(:hover) {
  color:rgb(4, 49, 104); ;
  border-color:rgb(4, 49, 104);;  
}

.navbar * .navbar-item.has-dropdown:hover .navbar-link:not(.is-arrowless) span,
.navbar * .navbar-item.has-dropdown:hover .navbar-link:not(.is-arrowless) a,
.navbar * .navbar-item:hover, /* war mit: * am Ende */
.navbar * .navbar-item:focus,
.navbar * .navbar-item:hover  /* war mit: * am Ende */
/* .navbar * .navbar-item:not(.has-dropdown):focus-within  war mit: * am Ende  */ {
  color:rgb(255, 174, 0) !important;
  border-color:rgb(255, 174, 0) !important;  
}

.navbar * a.button {
  background-color:transparent;
}

.navbar-brand:hover a.navbar-item {
  color :rgb(255, 174, 0);
}

.navbar-brand:hover * img {
  filter: brightness(0) saturate(100%) invert(79%) sepia(45%) saturate(5419%)
    hue-rotate(2deg) brightness(107%) contrast(103%);
}

.navbar:not(.is-scrolled) * .navbar-brand:not(:hover) * img {
  filter: none;
}

.navbar.is-scrolled * .navbar-brand:not(:hover) * img {
  filter: brightness(0) saturate(100%) invert(15%) sepia(36%) saturate(3811%) hue-rotate(197deg) brightness(89%) contrast(99%);
}


.navbar-item {
  font-size : 1.15em;
}

/*
span i.navbar-mail-hover {
  display:none;
}

span:hover i.navbar-mail-hover {
  display:inline;
}

span:hover i.navbar-mail {
  display:none
}

*/

.navbar-item * i.navbar-mail-hover {
  display:none;
}

.navbar-item:hover * i.navbar-mail-hover {
  display:inline;
  color:rgb(255, 174, 0);  
}


.navbar-item:hover * i.navbar-mail {
  display:none
}

.navbar * .navbar-brand .navbar-item {
  font-size : 1.3em;
}

.navbar-link:not(.is-arrowless) {
    padding-right: 0.75em !important;
}

.navbar-item.has-dropdown .navbar-link:not(.is-arrowless)::after {
  display:none !important;
  content: none;
}

.navbar-item.has-dropdown .navbar-link span {
    border: 3px solid;
    border-color: #043168;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    display: block;
    height: 0.625em;
    margin-top: -0.4em;
    pointer-events: none;
    margin-left:0.5rem;
    /*position: absolute;
    top: 50%;*/
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
}


@media screen and (max-width: 1024px) {

  .navbar:not(.scrolled) * .navbar-menu * ,
  .navbar:not(.scrolled) * .navbar-menu * a.navbar-item,  
  .navbar:not(.scrolled) * .navbar-menu * .navbar-item * a.button {
    color: rgb(4, 49, 104) !important;
    border-color: rgb(4, 49, 104) !important;
  }

  .navbar:not(.is-scrolled) * .navbar-brand:hover * ,
  .navbar:not(.is-scrolled) * .navbar-brand:focus * ,
  .navbar:not(.is-scrolled) * .navbar-brand:focus-within * {
    color:white !important;
  }

  .navbar.is-scrolled * .navbar-brand:hover * ,
  .navbar.is-scrolled * .navbar-brand:focus * ,
  .navbar.is-scrolled * .navbar-brand:focus-within * {
    color:rgb(4, 49, 104) !important;
  }  

  .navbar:not(.is-scrolled) * .navbar-brand:hover * img,
  .navbar:not(.is-scrolled) * .navbar-brand:focus * img,
  .navbar:not(.is-scrolled) * .navbar-brand:focus-within * img {
    filter:none;
  }  

  .navbar.is-scrolled * .navbar-brand:hover * img,
  .navbar.is-scrolled * .navbar-brand:focus * img,
  .navbar.is-scrolled * .navbar-brand:focus-within * img {
    filter: brightness(0) saturate(100%) invert(15%) sepia(36%) saturate(3811%) hue-rotate(197deg) brightness(89%) contrast(99%);
  }    

  .navbar-end {
    margin-left:1.25rem;
  }

  /*
  .navbar-link:not(.is-arrowless)::after  {
    position:absolute;
    left:-0.45rem;
    margin-top:0;
    display: none !important;
  }
  */

  .navbar-dropdown:not(.is-active) {
    display:none;
  }

  .navbar * a.button::after {
    content : "Contact us";
    margin-left:0.75rem;
  }  

  .navbar-end .navbar-item:last-child {
    top: 4.0rem;
    position: absolute;
    right: 0.5rem;
  }

  .navbar-end .navbar-item:nth-last-child(2) {
    top: 11rem;
    position: absolute;
    right: 0.5rem;
  }  

  .navbar-dropdown .navbar-item {
    color:rgb(4, 49, 104);
  }

  .navbar-dropdown .navbar-item.is-active {
    color:rgb(255, 174, 0) !important;
  }
 
  .navbar-item.has-dropdown .navbar-link:not(.is-arrowless) span {
    display:none;
    /* margin-left: -1.35rem; */
    /* margin-top: -1.35rem; */
  }

  .navbar-end .navbar-item:not(.has-dropdown),
  .navbar-end .navbar-item.has-dropdown .navbar-link {
    /* padding-left:1.2rem; */
  }


}


@media screen and (min-width: 1024px) {

  /* FIX DROPDOWN STAYS OPEN WHILE ROUTING */
    /* .navbar-item.is-hoverable:focus .navbar-dropdown, */
   /*.navbar-item.is-hoverable:hover .navbar-dropdown, */
    /*.navbar-dropdown:focus-within, */
  /*.navbar-item.is-hoverable .navbar-dropdown:hover */ /*,
  .navbar-item.is-hoverable .navbar-dropdown:focus,
  .navbar-item.is-hoverable .navbar-dropdown:focus-within*/ /*{
      display: block;
  }*/



  /*
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
      display: none !important;
  }
  /* FIX DROPDOWN STAYS OPEN WHILE ROUTING */


    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block !important;
    }
    .navbar-item.is-hoverable:focus-within .navbar-dropdown {
        display: none;
    }


  .navbar:not(.is-scrolled) * .navbar-dropdown a.navbar-item.is-active {
      text-underline-offset: 0.4rem !important;       
  }

  .navbar:not(.is-scrolled) * .navbar-brand .navbar-item {
    font-size : 1.8em;
    transition: font-size 0.5s;
  }

  .navbar.is-scrolled * .navbar-brand .navbar-item {
    font-size : 1.5em;
    transition: font-size 0.5s;
  }

  .navbar:not(.is-scrolled),
  .navbar-underlay:not(.is-scrolled) {
    min-height: 6em;
    transition: min-height 0.5s;    
  }

  .navbar.is-scrolled,
  .navbar-underlay.is-scrolled {
    min-height: 4em;
    transition: min-height 0.5s;    
  }  

  .navbar-item.is-active,
  .navbar-item .is-active,
  a.navbar-item.is-active {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  .navbar:not(.is-scrolled) * .navbar-item .is-active,
  .navbar:not(.is-scrolled) * .navbar-item.is-active{
    text-underline-offset : 1rem;
    transition: text-underline-offset 0.5s
  }

  .navbar.is-scrolled * .navbar-item .is-active,
  .navbar.is-scrolled * .navbar-item.is-active{
    text-underline-offset : 0.5rem;
    transition: text-underline-offset 0.5s
  }  

  .navbar * .navbar-link.is-active:hover,
  .navbar * .navbar-item.is-active:hover {
    color: rgb(255, 174, 0);  
  }

  .navbar-item * .navbar-mail::before {
    display: inline !important;
  }

  .navbar-item * a.button:hover * svg.navbar-mail {
    display: none !important;
  }

  .navbar-item * svg.navbar-mail-hover {
    display: none !important;
  }

  .navbar-item * a.button:hover * svg.navbar-mail-hover {
    display: inline !important;
  }


  .navbar:not(.is-scrolled) * .navbar-item:not(:hover) * a.button {
    color: white;
    background: none;
    border-color: white;
  }

  .navbar * .navbar-item:hover * a.button,
  .navbar.is-scrolled * .navbar-item:hover * a.button{
    color: rgb(255, 174, 0);
    background: none;
    border-color: rgb(255, 174, 0);
  }  


  /* background and color on hover for envelope */
  .navbar * span.navbar-item:hover i {
    color: rgb(255, 174, 0); 
  }

  .navbar * .button.is-outlined {
    background-color:transparent;
  }

  .navbar * .navbar-item:hover .button.is-outlined {
    border-color: rgb(255, 174, 0); 
    color: rgb(255, 174, 0);     
  }  

  .navbar * span.navbar-item:hover {
   background-color: rgba(255, 255, 255, 0.2);
   background-color: rgba(255, 255, 255, 0.2);
  }


    /* color on hover for flag */
    .navbar * .navbar-item:hover span,
    .navbar * .navbar-item:hover span i {
    color: rgb(255, 174, 0); 
  }

}
</style>