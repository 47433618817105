<template>
  <!--<li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">{{locale}}-->
  <a class="navbar-item" v-on:click="switchLocale()"><span><i class="fas fa-flag"></i>&nbsp; {{nextLocale}} </span></a>
</template>


<script>
export default {
  name: 'LocaleSwitcher',
  data() {
    return {
      nextLocale : this.$i18n.fallbackLocale,
      currentLocale : this.$i18n.locale
      //locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  },
  methods: { 
    switchLocale() {      
      {
        this.$i18n.locale = this.nextLocale;
        var tmp = this.currentLocale;
        this.currentLocale = this.nextLocale;
        this.nextLocale = tmp;
      }
    }
  }
}
</script>
