export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rechte vorbehalten."])},
        "contact": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind für Sie erreichbar:"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail schreiben"])},
          "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktformular"])}
        },
        "services": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEISTUNGEN"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten Ihnen:"])},
          "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kundenspezifische Lösungen"])},
          "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["präzise Messtechnik"])},
          "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetenz in Technologie"])}
        },
        "information": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATIONEN"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahren Sie mehr:"])},
          "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Advanced Quantum"])},
          "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
          "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
        },
        "ressources": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESSOURCEN"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles mit Recht:"])},
          "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB / AVB"])},
          "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
          "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])}
        }
      },
      "en": {
        "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved."])},
        "contact": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are available:"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write E-Mail"])},
          "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form"])}
        },
        "services": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICES"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide you with:"])},
          "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom solutions"])},
          "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision metrology"])},
          "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence in technology"])}
        },
        "information": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATION"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more:"])},
          "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Advanced Quantum"])},
          "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
          "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
        },
        "ressources": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESSOURCES"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything legal:"])},
          "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
          "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
          "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])}
        }
      }
    }
  })
}
