
<template>
  <section id="hero" class="hero is-fullheight">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head"></div>
    <top-navbar><slot></slot></top-navbar>
    <!-- Hero content: will be in the middle -->
    <div class="hero-body" style="color: white">
      <div class="container has-text-centered pt-6">
        <p class="title mt-6" style="color: white">
          {{ t('title') }}
        </p>
        <p class="subtitle" style="color: white">
          {{ t('subtitle') }}
        </p>
        <p class="mb-6">
          {{ t('p1') }}
        </p>
        <router-link class="button mt-6 is-medium" to="/kontakt"
          ><span>{{ t('button') }}</span></router-link>
      </div>
    </div>
    <div class="hero-arrow has-text-centered is-size-1 hero-arrow" @click="onDownClick">
      <i class="fa-solid fa-angle-down"></i>
    </div>
  </section>
</template>

<script>
import {useI18n} from 'vue-i18n';

import TopNavbar from "@/components/TopNavbar.vue";

export default {
  name: "HeroSection",
  components: {
    TopNavbar,
  },
  methods: {
    onDownClick() {
      var hero = document.getElementById("hero");
      window.scroll({top: hero.scrollHeight - 62, behavior:"smooth"});
      // scrollIntoView({behavior: "smooth"})
    }
  },
  setup() {
    const { t } = useI18n();
    return { t };
  }
}
</script>

<style scoped>
.hero {
    background:linear-gradient(45deg, rgba(4, 49, 104, 0.8) 15%, 
                                      rgba(48, 71, 9, 0.5) 60%, 
                                      rgba(89, 81, 48, 0.5) 75%,
                                      rgb(135, 93, 0.5) 100%) 0% 0%,
                                        url('../assets/images/hero.jpg') center center;
    background-size:cover;
}

.hero-body * .button:hover {
    background-color: white;
    color:rgb(255, 174, 0);
    border-color:white;
}

.hero-body * .button {
    background-color: rgb(255, 174, 0);
    color:white;
    border-color:rgb(255, 174, 0);
    font-weight:600;
}

:not(.hero-body *)  .button:hover {
    background-color: white;
    color:rgb(255, 174, 0);
    border-color:white;
}

:not(.hero-body *)  .button {
    background-color: rgb(255, 174, 0);
    color:white;
    border-color:rgb(255, 174, 0);
    font-weight:600;
}

.hero-arrow {
  cursor:pointer;
  padding: 0.5em;
  color:white;
  animation:bounce 2s infinite ease-in;
}

.hero-arrow:hover {
  color:rgb(255, 174, 0);

}

@keyframes bounce {
  0%, 20%, 30%, 34% {
    transform: translate(0px, 0px);
  }
  10% {
      transform: translate(0px, -50px);
  }
  25% {
      transform: translate(0px, -20px);
  }
  32% {
      transform: translate(0px, -10px);
  }
}


@-webkit-keyframes bounce {
  0%, 20%, 30%, 34% {
    transform: translate(0px, 0px);
  }
  10% {
      transform: translate(0px, -50px);
  }
  25% {
      transform: translate(0px, -20px);
  }
  32% {
      transform: translate(0px, -10px);
  }
}
@-moz-keyframes bounce {
  0%, 20%, 30%, 34% {
    transform: translate(0px, 0px);
  }
  10% {
      transform: translate(0px, -50px);
  }
  25% {
      transform: translate(0px, -20px);
  }
  32% {
      transform: translate(0px, -10px);
  }
}
@-o-keyframes bounce {
  0%, 20%, 30%, 34% {
    transform: translate(0px, 0px);
  }
  10% {
      transform: translate(0px, -50px);
  }
  25% {
      transform: translate(0px, -20px);
  }
  32% {
      transform: translate(0px, -10px);
  }
}
@-ms-keyframes bounce {
  0%, 20%, 30%, 34% {
    transform: translate(0px, 0px);
  }
  10% {
      transform: translate(0px, -50px);
  }
  25% {
      transform: translate(0px, -20px);
  }
  32% {
      transform: translate(0px, -10px);
  }
}
@keyframes bounce {
  0%, 20%, 30%, 34% {
    transform: translate(0px, 0px);
  }
  10% {
      transform: translate(0px, -50px);
  }
  25% {
      transform: translate(0px, -20px);
  }
  32% {
      transform: translate(0px, -10px);
  }
}



</style>

<i18n src="@/content/hero.json" lang="json5">
</i18n>