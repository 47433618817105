export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecken Sie Ihren Quantenvorteil"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortgeschrittene Messtechnologie für das 21. Jahrhundert"])},
        "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir liefern Lösungen und zukunftsweisende Quantentechnologie welche Sie dabei unterstützt das Unmögliche neu zu definieren."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JETZT KONTAKTIEREN"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover your quantum advantage"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Measurement Technology for the 21st century"])},
        "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide solutions and advanced quantum technology that can help you redefine the impossible."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GET IN TOUCH"])}
      }
    }
  })
}
