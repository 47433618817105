import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import withUUID from "vue-uuid";

import router from './router'
import i18n from "./i18n";

import '@/assets/main.scss'

const app = createApp(App);

withUUID(app);
app.use(i18n);
app.use(router);
app.use(VueAxios, axios)
app.mount('#app');

