<template>
  <footer class="footer has-background-grey mt-6">
    <div class="container">
      <div class="columns">
        <div class="column has-text-left-tablet">
          <h1>{{ t("contact.heading") }}</h1>
          <hr />
          <p>{{ t("contact.p1") }}</p>
          <ul>
            <li>
              <span
                ><i class="fa-solid fa-phone-flip" aria-hidden="true"></i
                ><a href="tel:+4971912299712"
                  >+49&nbsp;7191&nbsp;2299&nbsp;712</a
                ></span
              >
            </li>
            <li>
              <span>
                <i class="fa-solid fa-at"></i>
                <a :href="mailtoPrefix + mailtoEmail">{{ t('contact.mail') }}</a>
              </span>
            </li>
            <li>
              <span>
                <i class="fa-solid fa-paper-plane"></i>
                <router-link to="/contact">{{ t('contact.form') }}</router-link>
              </span>
            </li>
          </ul>
        </div>
        <div class="column has-text-left-tablet">
          <h1>{{ t("services.heading") }}</h1>
          <hr />
          <p>{{ t("services.p1") }}</p>
          <ul>
            <li>
              <span
                ><router-link to="/solutions">{{
                  t("services.li1")
                }}</router-link></span
              >
            </li>
            <li>
              <span
                ><router-link to="/products">{{
                  t("services.li2")
                }}</router-link></span
              >
            </li>
            <li>
              <span
                ><router-link to="/services">{{
                  t("services.li3")
                }}</router-link></span
              >
            </li>
          </ul>
        </div>
        <div class="column has-text-left-tablet">
          <h1>{{ t("information.heading") }}</h1>
          <hr />
          <p>{{ t("information.p1") }}</p>          
          <ul>
            <li>
              <span
                ><router-link  to="/about">{{ t("information.li1") }}</router-link ></span
              >
            </li>
            <li>
              <span
                ><a :href="mailtoPrefix + supportEmail">{{
                  t("information.li2")
                }}</a></span
              >
            </li>
            <li>
              <span
                ><router-link to="/contact">{{ t("information.li3") }}</router-link ></span
              >
            </li>
          </ul>
        </div>
        <div class="column has-text-left-tablet">
          <h1>{{ t("ressources.heading") }}</h1>
          <hr />
          <p>{{ t("ressources.p1") }}</p>          
          <ul>
            <li>
              <span
                ><router-link to="/terms">{{
                  t("ressources.li1")
                }}</router-link></span
              >
            </li>
            <li>
              <span
                ><router-link to="/privacy">{{
                  t("ressources.li2")
                }}</router-link></span
              >
            </li>
            <li>
              <span
                ><router-link to="/imprint">{{
                  t("ressources.li3")
                }}</router-link></span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="columns is-centered has-text-centered">
        <div class="column is-6">
          <p>
            &copy; <span v-if="startYear == currentYear">{{ startYear }}</span
            ><span v-else>{{ startYear }}&ndash;{{ currentYear }}</span>
            Advanced Quantum GmbH. {{ t("copyright") }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "PageFooter",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      startYear: 2022,
      currentYear: new Date().getFullYear(), // 2020
      mailtoEmail: atob("bWFpbHRvQGFkdmFuY2VkLXF1YW50dW0uZGU="), // btoa("mailto@advanced-quantum.de")
      supportEmail: atob("c3VwcG9ydEBhZHZhbmNlZC1xdWFudHVtLmRl"), // btoa("support@advanced-quantum.de")
      mailtoPrefix: atob("bWFpbHRvOg=="), // btoa("mailto:")
    };
  },
};
</script>

<style scoped>
.footer {
  background: radial-gradient(#cdcdcd, #727272);
  padding: 1rem 1.5rem 1rem;
}

.footer * ul li {
  padding: 0.25em 0;
}

.footer * li span i {
  padding-right: 0.75em;
}

.footer * p {
  padding-bottom: 0.5em;
}

.footer * hr {
  margin: 0.5em -1em;
}

.footer * .column {
  margin: 1.25em;
}

.footer {
  color: rgb(4 49 104);
  /*font-variant: all-small-caps;*/
  line-height: 2rem;
  font-weight: 300;
}

.footer * ul,
.footer * span a,
.footer * ul,
.footer * span a {
  color: rgb(4 49 104);
}

.footer * li span:hover,
.footer * li span:hover a {
  color: rgb(255, 174, 0);
}

.footer * .column:not(:first-of-type) ul li a:before {
  content: "+";
  padding-right: 5px;
}
</style>

<i18n src="@/content/footer.json"  lang="json5">
</i18n>